import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled'
import { AccountContext } from './Accounts';

import UserPool from './UserPool';
import { UserContext } from './context';
import Cookies from "js-cookie";
// import ForgotPassword from './forgot_password';


export default () => {

    const Input = styled.input`
        padding: 0.5em;
        line-height: 10pt;
        width: 90%;
        text-align:center;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin: 10px 0 10px 0;
        color: black;
        background: #e6e6e6;
        border: black;
        border-width: 3pt;
        border-radius: 3px;
        font-size: 12pt;
    `;

    const Main = styled.div`
        padding: 10pt 10pt 20pt 10pt;
        text-align: center;
        background-color: #a3a3a3;
        width: 400px;
        margin: 0 auto;
        margin-top: 50px;
        font-size: 16pt;

        border: 0.08em solid #000000;
        border-radius: 5pt;
    `;


    const ShowForm = styled.button `
        display:inline-block;
        border:0.18em solid #000000;
        padding: 5px 22px 5px 22px;
        margin: 0px 40px 40px 40px;

        text-align: center;

        border-radius:0px;
        box-sizing: border-box;
        text-decoration:none;
        color:#000000;
        text-align:center;
        transition: all 0.1s;
        font-size: 14pt;
        font-weight: 500;

        :hover{
            color:#ffffff;
            background-color:#000000;
            cursor: pointer;
        }
    `

    const Submit = styled.button `
        display:inline-block;
        border:0.18em solid #000000;
        padding: 5px 22px 5px 22px;
        margin: 70px 0px 0px 0px;

        text-align: center;

        border-radius:0px;
        box-sizing: border-box;
        text-decoration:none;
        color:#000000;
        text-align:center;
        transition: all 0.1s;
        font-size: 14pt;
        font-weight: 500;


        :hover{
            color:#ffffff;
            background-color:#000000;
            cursor: pointer;
        }
    `

    const ButtonBox = styled.div `
        width: 400px;
        position: relative;
    `

    const StateMessage = styled.div`
        padding-bottom: 10pt;
        font-size: 20pt;
        font-weight: 500;
    `

    const SubMessageSignup = styled.div`
        padding-bottom: 20pt;
        font-size: 10pt;
    `
  
    const SubMessageLogin = styled.div`
        padding-bottom: 30pt;
        font-size: 10pt;
    `
  
    const SignupMessage = styled.div`
        padding-bottom: 20pt;
        font-size: 10pt;
        text-align: left;
        margin-left: 25pt;
    `

    const AuxSignupMessage = styled.div`
        padding-bottom: 20pt;
        font-size: 10pt;
    `


    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    let emailLoginId = React.createRef();
    let passwordLoginId = React.createRef();

    const [emailSignUp, setEmailSignUp] = useState('');
    const [passwordSignUp, setPasswordSignUp] = useState('');
    let emailSignUpId = React.createRef();
    let passwordSignUpId = React.createRef();

    const [showLogin, setShowLogin] = useState(true);
    const [showSignUp, setShowSignUp] = useState(false);
    
    const { authenticate } = useContext(AccountContext);
    const { getSession } = useContext(AccountContext);

    // usecontext, that is important
    const {isUserLogged, setIsUserLogged, userEmail, setUserEmail} = useContext(UserContext); // ! 
    // const [userEmail, setUserEmail] = useState(''); // to check if the whole webpage should be shown or not

    const [signupMessage, setSignupMessage] = useState();
    const [loginMessage, setLoginMessage] = useState();


    useEffect(()=>{
        if (emailLogin) { 
            authenticate(emailLogin, passwordLogin)
            .then(data => {
                // console.log('Logged in!', data);
                setIsUserLogged(true);
                getSession().then(({ user, email, attributes}) => {
                    setUserEmail(email);
                    // console.log("Session: ", user);
                    // console.log("Session email: ", email);
                    // console.log("att: ", attributes);
                });
            })
            .catch(err => {
                console.error('Failed to login!', err);
                setLoginMessage(err.message);
            })

        }
    }, [passwordLogin]);

/* Cookies */
    useEffect(() => {
        const idTokenFromCookie = Cookies.get("idToken");
        if (idTokenFromCookie) {
          // Directly set the user as logged in and fetch session data
          console.log('token', idTokenFromCookie);
          setIsUserLogged(true);
          getSession().then(({ email }) => {
            setUserEmail(email);
          });
        }
      }, []); // The empty dependency array ensures this useEffect runs only once
    

    const onLogin = event => {
        event.preventDefault();
        setEmailLogin(emailLoginId.current.value);
        setPasswordLogin(passwordLoginId.current.value);
    };


    useEffect(()=>{
        if (emailSignUp) {
            UserPool.signUp(emailSignUp, passwordSignUp, [], null, (err, data) => {
                if (err) {
                    console.log(err);
                    setSignupMessage(err.message);
                }
                else {
                    console.log(data);
                    setSignupMessage('A signup request has been made. We will process your account soon.')
                }
            });
        }
    }, [passwordSignUp]);

    const onSignUp = event => {
        event.preventDefault();
        setEmailSignUp(emailSignUpId.current.value);
        setPasswordSignUp(passwordSignUpId.current.value);
    };

    return (
        <div>

            {!isUserLogged ? (
                <Main>
                    <ButtonBox>
                        {showLogin && 
                            <>
                                <StateMessage>LOGIN STATE</StateMessage> 
                                <ShowForm  onClick={ () => {setShowLogin(false); setShowSignUp(true); setSignupMessage()}}> SignUp </ShowForm>
                                <ShowForm  onClick={ () => {setShowLogin(true); setShowSignUp(false)}}> Login </ShowForm>
                                <SubMessageLogin>Please enter your email and password below</SubMessageLogin>
                            </> 
                        }
                        {showSignUp && 
                            <>
                                <StateMessage>SIGNUP STATE</StateMessage> 
                                <ShowForm  onClick={ () => {setShowLogin(false); setShowSignUp(true)}}> Sign up </ShowForm>
                                <ShowForm  onClick={ () => {setShowLogin(true); setShowSignUp(false); setLoginMessage()}}> Login </ShowForm>
                                <SubMessageSignup>Please enter your email and your desired password below</SubMessageSignup>
                                <SignupMessage>Your password must have at least: 
                                    <li>8 characters in total</li>
                                    <li>1 mayus character</li>
                                    <li>1 number</li>
                                    <li>1 special character (#, !, ~, ...)</li>
                                </SignupMessage>
                            </>
                        }
                    </ButtonBox>

                    { showLogin ?  (
                        <div> 
                            <div> <Input defaultValue="address@mail.com" ref={emailLoginId} type="text" /> </div>
                            <div> <Input defaultValue="password" ref={passwordLoginId} type="password" /> </div>
                            {loginMessage ? (
                                <AuxSignupMessage> {loginMessage} </AuxSignupMessage>
                            ) : (null)}
                            <div> <Submit onClick={onLogin}>Login</Submit> </div>
                        </div>
                    ): (null)}


                    { showSignUp ? (
                        <div>
                            <div> <Input defaultValue="address@mail.com" ref={emailSignUpId} type="text" /> </div>
                            <div> <Input defaultValue="passwordA1!" ref={passwordSignUpId} type="password" /> </div>
                            {signupMessage ? (
                                <AuxSignupMessage> {signupMessage} </AuxSignupMessage>
                            ) : (null)}
                            <div> <Submit onClick={onSignUp}>Sign up</Submit> </div>
                        </div>
                    ): (null)}


                </Main>

            ) : (null)}


            {/* {userEmail}
            <ForgotPassword /> */}
        </div>
    );

};
