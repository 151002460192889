import React, { createContext, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./UserPool";
import { useState } from "react";
import Cookies from "js-cookie";


const AccountContext = createContext();

const Account = props => {
  const [idToken, setIdToken] = useState(() => {
      return Cookies.get("idToken") || null;
  });
  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes
            });
          }
        });
      } else {
        reject();
      }
    });

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const idToken = data.getIdToken().getJwtToken();
          setIdToken(idToken); 
          resolve(data);
          Cookies.set("idToken", idToken, { expires: 7 }); // guarda l'idtoken en una cookie que dura 7 dies
          console.log("token cookie:", Cookies.get("idToken"));
          console.log("token id:", idToken);
          console.log("onSuccess:", data);
        },

        onFailure: err => {
          // console.error("onFailure:", err);
          reject(err);
        },

        newPasswordRequired: data => {
          // console.log("newPasswordRequired:", data);
          resolve(data);
        }
      });
    });
    const authenticateAdmin = async (Username, Password) => {
      const isAdminUser = Username === 'mtorrent@sternainnovation.com';
    
      if (!isAdminUser) {
        throw new Error('You are not an admin user');
      }
    
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
    
      return new Promise((resolve, reject) => {
        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            const idToken = data.getIdToken().getJwtToken();
            setIdToken(idToken);
            resolve(data);
            Cookies.set("idToken", idToken, { expires: 7 });
            console.log("token cookie:", Cookies.get("idToken"));
            console.log("token id:", idToken);
            console.log("onSuccess:", data);
          },
    
          onFailure: (err) => {
            reject(err);
          },
    
          newPasswordRequired: (data) => {
            resolve(data);
          }
        });
      });
    };

    useEffect(() => {
      if (idToken) {
        Cookies.set("idToken", idToken, { expires: 7 }); 
        console.log("token cookie:", Cookies.get("idToken"));
        console.log("token id:", idToken);

      }
    }, [idToken]);

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        authenticateAdmin
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
