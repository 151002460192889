import React, { useState, useEffect } from 'react';
import { get_docClient } from '../../AWS-SDK/AWS';
import { Line } from 'react-chartjs-2';
import "./gesab.css";
import { Link } from 'react-router-dom';
import "../../../menu.css"

const UsersDevicesTableDashboard = () => {

    const [consoles, setConsoles] = useState([]);

    const [selectedDevices, setSelectedDevices] = useState([]);
    const [selectedData, setSelectedData] = useState([]); // Store data for selected devices
  
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const timeFilterOptions = [
      { value: 7, label: 'Last Week' },
      { value: 30, label: 'Last Month' },
      { value: 60, label: 'Last 2 Months' },
      { value: 180, label: 'Last 6 Months' },
      { value: 365, label: 'Last Year' },
     // { value: null, label: 'All'}
    ];
    useEffect(() => {
      fetchConsoles(); // Fetch inhaler names if needed
    }, []);
    
    const fetchConsoles = async () => {
  
      try {
  
        const docClient = get_docClient();
        const params = {
          TableName: 'Gesab_Devices',
        };
  
        const data = await docClient.scan(params).promise();
        // const consoleNames = data.Items.map(item => item.Device_Name);
        const consoleNames = data.Items
        setConsoles(consoleNames);
        console.log('CONSOLE NAMES', consoleNames);
;
        
      } catch (error) {
        console.error('Error fetching console names', error);
      }
  
    };
  
    const handleDateFilter = async () => {
      console.log('Start Date Filter:', startDateFilter);
      console.log('End Date Filter:', endDateFilter);
  
      const filteredData =[];
     
      const startUnixTime = new Date(startDateFilter).getTime() / 1000;
      const endUnixTime = (new Date(endDateFilter).getTime() / 1000) + 86400; // Adding 24 hours to include the end day
    
      console.log('startUnixTime', startUnixTime);
      console.log('endUnixTime', endUnixTime);
           
      for (const deviceName of selectedDevices) {
        const tableData = await fetchTableData(deviceName, startUnixTime, endUnixTime);
        const deviceData = tableData.map(item => ({
          ...item,
          device: deviceName,
        }));
        filteredData.push(...deviceData);
      }
      
      setSelectedData(filteredData);
      console.log('selectedData after filtering', selectedData);
    }; 
    
    const handleConsoleCheckboxChange = (event) => {
  
      const consoleName = event.target.value;
      if (event.target.checked) {
        setSelectedDevices(prevSelected => [...prevSelected, consoleName]);
      } else {
        setSelectedDevices(prevSelected => prevSelected.filter(name => name !== consoleName));
  
      }
  
    };
  
    const processTimestamp = (Timestamp) => {
  
      const [date, time] = Timestamp.split('T');
      const [day, month, year] = date.split('-');
      const [hour, minute, second] = time.split(':');
      const utcTimestamp = Date.UTC(year, month - 1, day, hour, minute, second);
  
      return new Date(utcTimestamp).toLocaleString();
  
    };
  
    
    const handleDisplaySelected = async () => {
      const mergedData = [];
      console.log('device names:', selectedDevices);
      for (const deviceName of selectedDevices) {
        const tableData = await fetchTableData(deviceName, null, null);
        const deviceData = tableData.map(item => ({
          ...item,
          device: deviceName,
        }));
        mergedData.push(...deviceData);
      }
      // Update the selectedData state with the merged data
      setSelectedData(mergedData); // Use await here
    };
    
    
   const handleTimeFilter = async (numDays) => {
      const currentDate = new Date();
      const endDate = currentDate.toISOString().split(' ')[0];
      
      const subtractedDate = new Date();
      subtractedDate.setDate(currentDate.getDate() - numDays);
      const startDate = subtractedDate.toISOString().split(' ')[0];
    
      setStartDate(startDate);
      setEndDate(endDate);
    
      const startUnixTime = new Date(startDate).getTime() / 1000;
      const endUnixTime = (new Date(endDate).getTime() / 1000) + 86400; // Adding 24 hours to include the end day
  
      console.log('startUnixTime', startUnixTime);
      console.log('endUnixTime', endUnixTime);
      const filteredData = [];    
      for (const deviceName of selectedDevices) {
        const tableData = await fetchTableData(deviceName, startUnixTime, endUnixTime);
        const deviceData = tableData.map(item => ({
          ...item,
          device: deviceName,
        }));
        filteredData.push(...deviceData);
      }
      setSelectedData(filteredData);
      console.log('selectedData after filtering', selectedData);
    }; 
    
    const fetchTableData = async (deviceName, startUnixTime, endUnixTime) => {
      console.log('deviceName: ', deviceName);
      try {
        const docClient = get_docClient();
        const params = {
          TableName: deviceName,
        };
        const data = await docClient.scan(params).promise();
        console.log('data: ', data.Items);
        // Sort the filtered data by Unix_Time in ascending order
       
        if (startUnixTime !== null && endUnixTime !== null) {
            // Filter the combined data for all selected devices
          const filteredData = data.Items.filter(item => {
          const unixTime = item.Unix_Time; // Replace with the actual attribute name in your table
          return (!startUnixTime || unixTime >= startUnixTime) &&
                     (!endUnixTime || unixTime <= endUnixTime);
        });
    
          return filteredData;
        } else {
          // If startUnixTime and endUnixTime are not provided, return all data
          return data.Items;
        }
      } catch (error) {
        console.error('Error fetching table data', error);
        return [];
      }
    };

  // ordenar la selectedData, conté la info de les taules ordenades
    const sortedData = selectedData.slice().sort((a, b) => a.Unix_Time - b.Unix_Time);
    console.log('sortedData:', sortedData);

    // const interval = setInterval(() => {
    //   // window.location.reload();
    //   handleDisplaySelected();
    // }, 30000);

return (
    <div className='principal'>
        <div className='container-opt_gesab'>
            <div className='checkbox-container_gesab'>
            <h2 style ={{marginTop: 25 +'px', marginRight: 25 + 'px', fontFamily:'Roboto'}}>Select Consoles to display</h2>
            {consoles.map(consoleName => (
                <div key={consoleName} className="console-label_gesab">
                <input
                    type="checkbox"
                    value={consoleName.Device_Name}
                    checked={selectedDevices.includes(consoleName.Device_Name)}
                    onChange={handleConsoleCheckboxChange}
                />
                {consoleName.Public_Name}
                </div>
            ))}
            <div className='button-wrapper_gesab'>
                <button className="apply-button_gesab" onClick={handleDisplaySelected}>Display</button>
            </div>
            </div>
        </div>
        <div className='gesab_body'>
            {sortedData.length > 0 && (
                <>
            
            {sortedData[sortedData.length-1].Name != 'Unknown' &&(
              <>
            <welcome>
              <div><p style ={{fontFamily:'Roboto'}}>WELCOME!</p></div>
              <div><p_name style ={{fontFamily:'Roboto'}}><b>{sortedData[sortedData.length-1].Name}</b></p_name></div>
            </welcome>
              </>)}
            <gesab_section id="gesab_table">
                <gesab_separator>
                    <gesab_h2 style ={{fontFamily:'Roboto'}}>HEIGHT ADJUSTMENTS</gesab_h2>
                </gesab_separator>
                <hr></hr>
                <div id='graphs_gesab'>
                    <div class='porcentajes' style={{'--porcentaje': ((sortedData[sortedData.length-1].Elevation)*570/125).toFixed(2)}}>
                      <svg width="200" height="200">
                        <circle r="90" cx="50%" cy="50%" pathlength="100" />
                        <circle r="90" cx="50%" cy="50%" pathlength="100" />
                      </svg>
                      <span style ={{fontFamily:'Roboto'}}>{sortedData[sortedData.length-1].Elevation.toFixed(0)} cm</span>
                    </div>

                    {sortedData[sortedData.length-1].Alarm == 2 &&(
                    <>
                        <gesab_warning>
                            <br/>
                            <p style ={{fontFamily:'Roboto'}}> Sitting for <b>2 hours</b></p>
                            <p style ={{fontFamily:'Roboto'}}> Please <b>STAND UP</b></p>
                        </gesab_warning>
                    </>
                    )}
                </div>
                <gesab_section id="llegenda">
                    <div class = "text1"> <p style ={{fontFamily:'Roboto'}}>Current Height</p> </div>
                </gesab_section>
            </gesab_section>
            <gesab_section id="gesab_main">
                <gesab_separator>
                    <gesab_h2 style ={{fontFamily:'Roboto'}}>ACTIVITY</gesab_h2>
                </gesab_separator>
                <hr></hr>
                <div id='graphs_gesab'>
                    <div class="chart">                    
                        <div class='porcentajes' style={{'--porcentaje': ((sortedData[sortedData.length-1].Work_Time/60)*570/480).toFixed(2)}}>
                          <svg width="200" height="200">
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                          </svg>
                          <span style ={{fontFamily:'Roboto'}}>{(sortedData[sortedData.length-1].Work_Time/60).toFixed(0)} min</span>
                        </div>
                    </div>

                    <div class="chart2">
                        <div class='porcentajes' style={{'--porcentaje': ((sortedData[sortedData.length-1].Sit_Time/60)*570/480).toFixed(2)}}>
                          <svg width="200" height="200">
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                          </svg>
                          <span style ={{fontFamily:'Roboto'}}>{(sortedData[sortedData.length-1].Sit_Time/60).toFixed(0)} min</span>
                        </div>
                    </div>

                    
                  
                    <div class="chart3">
                        <div class='porcentajes' style={{'--porcentaje': ((sortedData[sortedData.length-1].Stand_Time/60)*570/480).toFixed(2)}}>
                          <svg width="200" height="200">
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                          </svg>
                          <span style ={{fontFamily:'Roboto'}}>{(sortedData[sortedData.length-1].Stand_Time/60).toFixed(0)} min</span>
                        </div>
                    </div>
                </div>
                <gesab_section id="llegenda">
                    <div class = "text1"> <p style ={{fontFamily:'Roboto'}}>TOTAL WORK TIME</p> </div>
                    <div class = "text2"> <p style ={{fontFamily:'Roboto'}}>SIT POSITION</p> </div>
                    <div class = "text3"> <p style ={{fontFamily:'Roboto'}}>STAND POSITION</p> </div>
                </gesab_section>
            </gesab_section>
            
            <gesab_section id="gesab_sensors">
                <gesab_separator>
                    <gesab_h2 style ={{fontFamily:'Roboto'}}>CONSOLE SENSORS</gesab_h2>
                </gesab_separator>
                <hr></hr>
                <div id='graphs_gesab'>
                    <div class="chart">
                        <div class='porcentajes' style={{'--porcentaje': (sortedData[sortedData.length-1].Temperature*570/100).toFixed(2)}}>
                          <svg width="200" height="200">
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                          </svg>
                          <span style ={{fontFamily:'Roboto'}}>{sortedData[sortedData.length-1].Temperature.toFixed(1)} ºC</span>
                        </div>
                    </div>
                  
                    <div class="chart2">
                        <div class='porcentajes' style={{'--porcentaje': (sortedData[sortedData.length-1].Humidity*570/100).toFixed(2)}}>
                          <svg width="200" height="200">
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                          </svg>
                          <span style ={{fontFamily:'Roboto'}}>{sortedData[sortedData.length-1].Humidity.toFixed(1)} %</span>
                        </div>
                    </div>
                  
                    <div class="chart3">
                        <div class='porcentajes' style={{'--porcentaje': (sortedData[sortedData.length-1].Power*570/300).toFixed(2)}}>
                          <svg width="200" height="200">
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                            <circle r="90" cx="50%" cy="50%" pathlength="100" />
                          </svg>
                          <span style ={{fontFamily:'Roboto'}}>{sortedData[sortedData.length-1].Power.toFixed(0)} W</span>
                        </div>
                    </div>
                </div>
                <gesab_section id="llegenda">
                    <div class = "text4"> <p style ={{fontFamily:'Roboto'}}>Cabinet temperature</p> </div>
                    <div class = "text5"> <p style ={{fontFamily:'Roboto'}}>Cabinet humidity</p> </div>
                    <div class = "text6"> <p style ={{fontFamily:'Roboto'}}>Electricity consumption</p> </div>
                </gesab_section>
            </gesab_section>
            </>)}
        </div>
    </div>
    );
};

export default UsersDevicesTableDashboard;