import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'eu-west-1_7GK0mXS7c',
    ClientId: '4j726ovbkf7svqjqsj2a8hfdt6',
    // UserPoolId: 'eu-west-1_nBWRzYuZJ',
    // ClientId: 'vhkkec03ues42mu8krp44i6v',
    correctClockSkew: true
}

export default new CognitoUserPool(poolData);