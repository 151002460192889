import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled'
import './App.css';
import './../dashboard.css';
import {Helmet} from 'react-helmet';
 
// Cognito
import {Account, AccountContext} from './components/UserAuthentication/Accounts';
import WholeAuth from './components/UserAuthentication/whole_auth';

// Device selection menu
import Selection from "./components/Selection/choice_menu";

// Layout
import Header from './components/Layout/header';
import Footer from './components/Layout/footer';
import Content from './components/Layout/content';

// Context
import {UserContext} from './components/UserAuthentication/context';
import {SelectContext} from './components/Selection/context';

// Sort function
import Sort from './utils/sort';

// AWS functions
import {scanTable, get_docClient} from './AWS-SDK/AWS';
import { useContext } from 'react';
import Cookies from "js-cookie";
import { useHistory } from 'react-router-dom';

// Error message
var err_msg = "Default error message!";

let docClient = get_docClient();

function App () {
	
	const App = styled.div`
        position: absolute;
        min-height: 100%;
        min-width: 100%;
    `
    const Container = styled.div`
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 40px;
    `
	
	// Screen state
	const [screen, setScreen] = useState("LOGIN"); // to control the screen to use
	
	// Login states
	const [isUserLogged, setIsUserLogged] = useState(false); // to check if the whole webpage should be shown or not
    const [userEmail, setUserEmail] = useState(''); // user Email
	
	// Selection states
	const [isChoiceSubmitted, setIsChoiceSubmitted] = useState(false); // to check if the user has submitted the device selection
	const [selectedDevices, setSelectedDevices] = useState([]);
	const [isCheckAll, setIsCheckAll] = useState(false);
	
	// Names states
	const [paramNames, setParamNames] = useState({});
	const [devNames, setDevNames] = useState({});
	
	// Devices states
	const [centrals, setCentrals] = useState([]);
	const [peripherals, setPeripherals] = useState([]);
	const [centralPeripherals, setCentralPeripherals] = useState({});

	const handleLogout = () => {
		// Call the logout function from Accounts.js to handle the logout
		// (you may need to import the logout function from Accounts.js)
		// For example, if the logout function is exported as 'logout':
		// logout();
		setIsUserLogged(false)
		console.log("token cookie:", Cookies.get("idToken"));
		Cookies.remove("idToken");
		console.log("token cookie:", Cookies.get("idToken"));
	};

	const history = useHistory();

    useEffect(() => {
      const idToken = Cookies.get('idToken');
  
      // If there is no valid token, redirect to login
      if (!idToken) {
        history.replace('/loginEspot'); // Replace '/login' with the actual path to your login page
      }
    }, [history]);

	useEffect(()=>{
		// perform actions if it exits login screen
		if (userEmail && screen == "LOGIN"){
			// change to preselection to use loading page if necessary
			setScreen("PRESELECTION");
			// scan users-devicetypes table and find device types (group) assigned
			scanTable("Users_and_DeviceTypes").then(res => {
				if (res.exist){
					var correct_type = 0;
					for (var i=0; i<res.items.length; i++){
						if (res.items[i].Users == userEmail){
							var deviceTypes_string = res.items[i].DeviceTypes.split(', ');
							for (var a in deviceTypes_string){
								if (deviceTypes_string[a] == "Distance_Demo"){
									correct_type = 1;
									setScreen("SELECTION");
									break;
								}
							}
							break;
						}
					}
					if (correct_type == 1) setScreen("SELECTION");
					else {
						err_msg = "User cannot access eSpot data!";
						setScreen("ERROR");
					}
				}
				// users table does not exist
				else {
					err_msg = "Users table does not exist!";
					setScreen("ERROR");
				}
			});
		}
	}, [userEmail]);

    return (  
        <div className="App">
			<Helmet>
				<title>Sterna eSpot Dashboard</title>
				<link rel="icon" href="/es.png" />
			</Helmet>
			{screen != "ERROR" ? (
				<UserContext.Provider value={{isUserLogged, setIsUserLogged, userEmail, setUserEmail} }>
					{isUserLogged && docClient ? (
						<SelectContext.Provider value={{isChoiceSubmitted, setIsChoiceSubmitted, selectedDevices, setSelectedDevices, isCheckAll, setIsCheckAll} }>
							<App>
								<Header user={userEmail} handleLogout={handleLogout}/>
								<Selection/>
								<Footer />
							</App>
						</SelectContext.Provider>
					) : (
						<Account>
							<Container>
								<WholeAuth />
							</Container>
						</Account>
					) }
				</UserContext.Provider>
			) : (
				<div> {err_msg} </div>
			)}
        </div>
    );  
}  



export default App;
