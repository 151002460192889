import React from 'react';
import styled from '@emotion/styled'
import { Link } from 'react-router-dom';

const Header = (props) => {

    const Header = styled.div`
        font-weight: 100;
        font-family: 'Montserrat';
        font-size: 1.25em;
        text-align: center;
        padding: 0.1em 0.4em 0.15em 0.4em;
        display: flex;
        justify-content: space-between;
        background-color: #2e2e2e; 
        position: sticky;
        top: 0;
        height: 50px;
        border-radius:0px;
        border-width: 1.25pt;

        z-index: 100;
    `

    const Title = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    text-anchor: bottom;
    color: white;
    line-height: 15px;
    margin-top: 18px;
    `

    const User = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    text-anchor: bottom;
    color: white;
    line-height: 15px;
    margin-top: 18px;
    `

    const Creator = styled.div`
        display: flex;
        justify-content: space-between;
        top: 50%;
        text-anchor: middle;
        line-height: 0px;
        list-style-type:none;
    `

    const SternaLogoHeader = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    text-anchor: middle;
    color: white;
    line-height: 0px;       
    margin-right: 10pt;
    margin-top: 3.4pt;
    `
    /* LOG OUT BUTTON */
    const LogoutButton = styled.button`
        font-size: 1rem;
        font-weight: 500;
        color: white;
        background-color: transparent;
        border: none;
        cursor: pointer;
        line-height: 15px;
        margin-top: 18px;
    `

    const flatButton = styled.button`
        border-radius: 6px;
        background-color:white;
        padding: 0.5em 1.5em;
        border: 0;
        color: #33415C;
        transition: 0.25s ease;
        cursor: pointer;
        position: absolute;
        top: 70px;
        right: 400px;
        &:hover,
        &:focus {
            background-color: var(--c-gray-200);
        }   
    `


    const handleLogout = () => {
        // Call the logout function passed from App.js
        props.handleLogout();
    };


    return ( 

		<Header>

            <Creator>
                <li>
                    <SternaLogoHeader>
                        <img src={"/imgs/logo-sterna-st.png"} width="55px" alt="Logo"></img>
                    </SternaLogoHeader>
                </li>
                <li>
                    <Title>
                        STERNA INNOVATION DashBoard
                    </Title>
                </li>
            </Creator>


            {/* <ClientLogo>
                <img src={"./imgs/seeka-logo-inverted-removebg.png"} width="95px" alt="Logo"></img>
            </ClientLogo> */}

            <LogoutButton onClick={handleLogout}>Log out</LogoutButton>
            <User> {props.user}</User>
            <Link to="/admin" >
				<flatButton>Admininstrator Mode</flatButton>
			</Link>
            </Header>

     );
}
 
export default Header;