import React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import ArrowIcon from './ArrowIcon';
import Header from './header';
import './menu.css';
import Cookies from "js-cookie";




const Creator = styled.div`
	display: flex;
	top: 50%;
	text-anchor: middle;
	line-height: 0px;
	list-style-type:none;
	
`

const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 500;
	text-anchor: bottom;
	color: white;
	line-height: 15px;
	margin-top: 18px;
`

const SternaPlaceHolder = styled.div`
	font-size: 1.5rem;
	font-weight: 500;
    text-anchor: middle;
    color: white;
    line-height: 0px;       
    margin-right: 10pt;
	margin-bottom: 4%;
	margin-top: 4%;
	margin-left: 2%;

`

const InitBody = styled.div`
	background-color: lightblue;
	background-image: url("/imgs/imatge01.jpg");
	display: block;
	height:720px;
	background-position: center;
  	background-repeat: no-repeat;
  	background-size: cover;
`

const PlaceHolder = styled.div`
	height: 100%;
	width: 100%;
	background-color: black;
	display: block;
`


const Source = () => {

	// const [userEmail, setUserEmail] = useState(''); // user Email
	// const [isUserLogged, setIsUserLogged] = useState(false); // to check if the whole webpage should be shown or not

	// const handleLogout = () => {
	// 	// Call the logout function from Accounts.js to handle the logout
	// 	// (you may need to import the logout function from Accounts.js)
	// 	// For example, if the logout function is exported as 'logout':
	// 	// logout();
	// 	setIsUserLogged(false)
	// 	console.log("token cookie:", Cookies.get("idToken"));
	// 	Cookies.remove("idToken");
	// 	console.log("token cookie:", Cookies.get("idToken"));
	// };


    return (
		<PlaceHolder>
			<Creator>
                    <SternaPlaceHolder>
                        <img src={"/imgs/images174x65_main-logo.png"} width="auto" height="auto" alt="Logo" max-width="150%"></img>
                    </SternaPlaceHolder>
            </Creator>
			<InitBody>
				<img style ={{marginLeft: 25 +'%'}} src={"/imgs/sterna_moto_ca.png"} width="50%" height="50%" alt="msg" max-width="100%"></img>
			</InitBody>
			

		</PlaceHolder>
    );
}
 
export default Source;