import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled'
import './App.css';
import {Helmet} from 'react-helmet';
 
// Cognito
import {Account} from './components/UserAuthentication/Accounts';
// import Settings from './components/UserAuthentication/Settings';

import WholeAuth from './components/UserAuthentication/whole_auth';

// Layout
import Header from './components/Layout/header';
import Footer from './components/Layout/footer';
import Content from './components/Layout/content';

// Context
import {UserContext} from './components/UserAuthentication/context';

// Sort function
import Sort from './utils/sort';

// context
import { ContextDt } from "./components/Layout/devices_filter/Context_dt";
import { ContextD } from "./components/Layout/devices_filter/Context_d";


var AWS = require("aws-sdk");
var credentials = require('./AWS-SDK/credentials.json'); //(with path)

AWS.config.update({
    accessKeyId: credentials.aws_access_key_id,
    secretAccessKey: credentials.aws_secret_access_key,
    region:'eu-west-1',
    version: 'latest'
});

AWS.config.getCredentials(function(err) {
    if (err) { console.log("credentials not loaded: " + err.stack);}
    // else { console.log("AWS Access key:", AWS.config.credentials.accessKeyId); }
});

let docClient = new AWS.DynamoDB.DocumentClient({correctClockSkew: true});



const scanTable = async (tableName) => {
    // console.log("getting data");

    const params = {
        TableName: tableName,
        // ExpressionAttributeValues: {
        //     ":rain_threshold": "0.0",
        //     ":time_unix_threshold": "1593322329",
        //     ":seqNumber_threshold": 3950,
        //    }, 
        // FilterExpression: "rain >= :rain_threshold AND time_unix > :time_unix_threshold AND seqNumber > :seqNumber_threshold",
    };
    let scanResults = [];
    let items;
    do{
        items = await docClient.scan(params).promise();
        if (items) {
            items.Items.forEach((item) => {scanResults.push(item)});
            params.ExclusiveStartKey = items.LastEvaluatedKey;
        }
    }while (typeof items.LastEvaluatedKey != "undefined");
    
    return scanResults;
};


const doesTableExist = async (tableName) => {
    const params = {TableName: tableName, Limit: 1, };
    return await (docClient.scan(params).promise()
        .then((data)=>{
            // console.log(data);
            console.log("we have data in: " + tableName);
            return (true);
        })
        .catch((err) => {
            // console.log(err);
            // console.log("we have error in: " + tableName);
            return (false);
        }));
}


function App () {
    const [data, setData] = useState({});
    // const [DataOrdered, setDataOrdered] = useState({});

    const [isUserLogged, setIsUserLogged] = useState(false); // to check if the whole webpage should be shown or not
    const [userEmail, setUserEmail] = useState(''); // user Email

    const [wholeData, setWholeData] = useState(''); // json with multiple device types and multiples devices

    const [wholeDataOrdered, setWholeDataOrdered] = useState(); // json with multiple device types and multiples devices, ordered
    const [dtKeys, setDtKeys] = useState([]);
    const [dSubKeys, setDSubKeys] = useState([]);

   const [context_dt, setContext_dt] = useState([]); 
   const [context_d, setContext_d] = useState([]); 

    var DeviceTypes = [];
    var Devices = [];
    useEffect(()=>{
        if (isUserLogged) { // fer servir usecontext!
            scanTable("OutputsDB").then(async res => {setData(res);});
        }
    }, [isUserLogged]);

    var JsonAllData = {};
    useEffect(()=>{
        if (userEmail) { // read deviceTypes associated with the input email
            // console.log("Getting data associated with userEmail");

            scanTable("Users_and_DeviceTypes").then(async res => {
                for (var i = 0; i < res.length; i++){
                    if (res[i].Users === userEmail){ // user found
                        var DeviceTypes_string = res[i].DeviceTypes.split(', ');
                        for (var a in DeviceTypes_string) {
                            if (!(DeviceTypes_string[a].includes("eContainer"))) {
                                DeviceTypes.push(DeviceTypes_string[a]);
                            }
                        }
                        // console.log(DeviceTypes);
                    }
                }
            }).then( () => {
                if (!DeviceTypes) {
                    console.log("No devices or users found in the DB");
                }
                else { // read devices for every deviceType
                    scanTable("DeviceType_and_Devices").then(async res => {
                        for (var i = 0; i < res.length; i++){
                            if (DeviceTypes.includes(res[i].DeviceType)){ // DeviceType found
                                var k = DeviceTypes.indexOf(res[i].DeviceType);
                                Devices[k] = []; // Array inside an array. We will write to k row
                                var Devices_string = res[i].Devices.split(', ');
                                for (var a in Devices_string){
                                    Devices[k][a] = (Devices_string[a]);
                                }
                            }
                        }
                        // console.log(Devices);
                    }).then( async () => {
                        // Scan every device in the Devices array and create json

                        for (let j=0; j<DeviceTypes.length; j++) { // Iterate through dtypes
                            JsonAllData[DeviceTypes[j]] = []; // create arrays
                        }

                        setContext_dt( new Array(DeviceTypes.length).fill(true)); // initialize dt_k_bools context
                        var Context_d_aux = new Array(Devices.length) 

                        for (let j=0; j<Devices.length; j++) { // Iterate through rows, j moves vertically
                            Context_d_aux[j] = [];
                            // console.log(Devices);
                            // console.log(DeviceTypes);
                            for (let i=0; i<Devices[j].length; i++) { // Iterate through columns, i moves horitzontally
                                let device = Devices[j][i].concat("_OUTPUTS");

                                await doesTableExist(device).then(async (res) => {
                                    if (res) {
                                        // console.log("SCAN TABLE: " + device);
                                        await scanTable(device).then(async res => {
                                            JsonAllData[DeviceTypes[j]].push({[Devices[j][i]]: res}); // populate json object
                                            Context_d_aux[j].push(true); 
                                        }).then( () => {
                                            if (i==Devices[j].length-1 && j==Devices.length-1){ // stop condition
                                                // console.log(JsonAllData);
                                                setWholeData(JSON.stringify(JsonAllData));
                                                setContext_d(Context_d_aux);
                                            }
                                        });
                                    }
                                })
                            }
                        }
                    })
                };
            })
        }
    }, [userEmail]);



useEffect(()=>{ // wholeData

    var dt_k = [];
    var d_k = [];
    var d_k_sub = [];

    if (wholeData) {
        var wholeJson = JSON.parse(wholeData);

        dt_k = Object.keys(wholeJson);

        for (var j in dt_k) {
            d_k[j] = Object.keys(wholeJson[dt_k[j]]); // 0, 1, 2... 
            var aux = [];
            for (var i in d_k[j]){
                aux[i] = Object.keys(wholeJson[dt_k[j]][d_k[j][i]]);
            }
            d_k_sub[j] = aux;


            if (j == dt_k.length-1 && i == d_k[j].length-1) { // All iterations have been performed
                var content = [];
                var timeUnix = [];
                for (j in dt_k) { // iterate through device types
                    for (i in d_k_sub[j]) { // iterate through devices in each device type
                        for (var h in wholeJson[dt_k[j]][i][d_k_sub[j][i]]) { // iterate through each row of the given device
                            content[h] = (wholeJson[dt_k[j]][i][d_k_sub[j][i]][h]) 
                            timeUnix[h] = wholeJson[dt_k[j]][i][d_k_sub[j][i]][h].time_unix;
                        }
                        var SortOut = Sort(timeUnix, content); // Order according to time_unix 
                        wholeJson[dt_k[j]][i][d_k_sub[j][i]] = SortOut.data; // Write data to where it comes from

                        content = [];
                        timeUnix = [];
                    }
                }
                setWholeDataOrdered(wholeJson);
                setDtKeys(dt_k);
                setDSubKeys(d_k_sub);
                // console.log("d_k a app"); console.log(d_k);
                // console.log("d_k_sub a app"); console.log(d_k_sub);
            }

        }


    }
}, [wholeData]);
   


    const App = styled.div`
        position: relative;
        min-height: 100vh;
        padding-bottom: 110px; /* Should be equal to the height of your footer */
    `

    const Container = styled.div`
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 40px;
    `

    var aux_wholeDataOrdered = JSON.stringify(wholeDataOrdered);
    // console.log(wholeDataOrdered);
    // console.log(dtKeys);
    // console.log(dSubKeys);

    return (  
        <div className="App">
			<Helmet>
				<title>Sterna IoT Dashboard</title>
				<link rel="icon" href="/iot2.png" />
			</Helmet>
            <UserContext.Provider value={{isUserLogged, setIsUserLogged, userEmail, setUserEmail} }>
                {isUserLogged && wholeDataOrdered && dtKeys && dSubKeys && docClient ? (
                    <ContextD.Provider value={[context_d, setContext_d]} >
                         <ContextDt.Provider value={[context_dt, setContext_dt]}>
                              <App>
                                  {/* {context} */}
                                  <Header user={userEmail} />
                                  {/* <Content current_data={DataOrdered} wholeData={wholeDataOrdered} wholeDataOriginal={aux_wholeDataOrdered} dt_k={JSON.stringify(dtKeys)} d_k_sub={JSON.stringify(dSubKeys)} dC={docClient}  /> */}
                                  <Content  wholeDataOriginal={JSON.stringify(wholeDataOrdered)} dt_k={JSON.stringify(dtKeys)} d_k_sub={JSON.stringify(dSubKeys)} dC={docClient}  />
                                  <Footer />
                              </App>
                         </ContextDt.Provider>
                    </ContextD.Provider>
                ) : (
                    <Account>
                        <Container>
                            <WholeAuth />
                        </Container>
                    </Account>
                ) }
            </UserContext.Provider>
        </div>
    );  
}  



export default App;

// https://www.quora.com/What-are-some-good-ways-to-extract-one-single-column-from-a-DynamoDB-table
